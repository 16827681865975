.guides {
  display: flex;
  width: 100%;
  flex-direction: column;

  .message {
    font-weight: bold;
    color: #383b41;
    font-size: 1.8rem;
  }

  .article__related {
    margin: 0;
  }

  @include breakpoint(tablet) {
    flex-direction: row;
  }

  &__guide {
    background-color: $white;
    padding: 30px;
    letter-spacing: 0.5px;
    line-height: normal;
    flex-direction: column;
    flex: 2 1;

    &__container {
      display: flex;
    }

    &__checkbox {
      margin: auto auto auto 7px;
    }

    &__title {
      font-weight: bold;
      font-size: $xLargeText;
      margin: 30px 0 15px;
    }

    &__published {
      width: 40%;
      font-weight: 700;
      font-size: $vSmallText;
    }

    &__date {
      font-weight: 20;
    }

    &__content {
      font-weight: normal;
      font-size: $largeText;
      margin: 0 0 40px;
      padding: 0;
      line-height: 26px;

      p {
        margin: 0 0 20px;
      }

      ul {
        list-style-type: disc;
        padding-left: 20px;

        li {
          margin-bottom: 12px;
        }
      }

      a[href] {
        color: $astronaut;
      }
    }

    &__footer {
      display: flex;
      font-weight: normal;
      font-size: $smallText;
      line-height: 16px;
      border: 1px solid $medium-grey;
      border-left: 0;
      border-right: 0;
      padding: 5px 0;
    }

    &__link {
      text-decoration: none;
      text-transform: capitalize;
      color: $tuna;
      font-size: $smallText;
    }

    .article__date-published {
      strong {
        color: $astronaut;
      }
    }

    &.print {
      .page-actions {
        border-bottom: 0;
      }
    }
  }
}
