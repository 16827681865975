.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100vw;
  position: relative;

  .webp & {
    background: transparent url('/images/assets/hero-login.webp') no-repeat
      right 65% / cover;
  }

  .no-webp & {
    background: transparent url('/images/assets/hero-login.jpg') no-repeat right
      65% / cover;
  }

  @include breakpoint(tablet) {
    height: 100vh;
  }

  // &:before {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   background-color: #da291c;
  //   height: 141px;
  //   top: -56px;
  //   left: 26px;
  //   transform: skew(60deg) rotate(-5deg);
  // }

  &__title {
    color: #75787b;
    margin: 20px auto 40px;
    text-transform: uppercase;
    font-weight: normal;
    text-align: left;
    display: flex;
    align-items: center;
    background-position: center;
    height: 70px;
    min-height: 70px;
    width: 240px;
    background-size: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(tablet) {
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
    }
  }

  &__boxes {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 20px;

    @include breakpoint(large-mobile) {
      width: 400px;
    }

    @include breakpoint(tablet) {
      width: 260px;
    }
  }

  &__box {
    background-color: $white;
    border-radius: 5px;
    padding: 25px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: auto;

    &__text {
      margin: 0;
    }

    .button {
      margin-top: auto;
    }

    &:first-child {
      margin: 0 0 20px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 4px;
    min-width: auto;
    width: calc(100% - 40px);
    padding: 24px;
    margin: 20px 20px 0;

    @include breakpoint(large-mobile) {
      width: 400px;
      min-width: 400px;
    }

    @include breakpoint(tablet) {
      width: 400px;
      min-width: 400px;
      margin: 20px 0 20px 20px;
    }

    &__field {
      padding: 15px;

      &:first-of-type {
        margin-bottom: 20px;
      }
    }

    &__header {
      font-size: $largeText;
      margin: 10px 0 0 2px;
    }

    &__link {
      margin: 30px 0 0;
      color: #1f2a44;
      margin-top: auto;
      cursor: pointer;
      text-decoration: underline;
    }

    label {
      margin: 0;
    }

    .button {
      padding: 10px 30px;
      float: right;
    }
  }

  &__content__header {
    color: #1f2a44;
    font-size: $largeText;
    margin-top: 0;
  }

  // .link {
  //   @include breakpoint(handhelds) {
  //     color: red;
  //   }

  //   @include breakpoint(medium-screens) {
  //     color: green;
  //   }

  //   @include breakpoint(wide-screens) {
  //     color: blue;
  //   }
  // }
}
