.guides__browser {
  background-color: $geyser;
  flex: 1;

  @include breakpoint(tablet) {
    min-width: 420px;
    max-width: 420px;
  }
}

.browser__collection__link {
  margin: 2px auto auto 15px;
  font-weight: bold;
  padding: 15px;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  display: block;
  text-decoration: none;
  font-size: 1.8rem;
  background-color: $porcelain;
  border-bottom: 2px solid $frenchGrey;
  color: $trout;
}

.browser__section {
  display: block;
  font-weight: bold;
  color: $tuna;
  padding: 15px 5px 15px 30px;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  font-size: $mediumText;
  text-decoration: none;

  &.active {
    background-color: $tuna;
    color: $white;
  }
}

.browser-dropdown {
  height: auto;
  position: relative;

  &__parent {
    display: flex;
    font-weight: bold;
    color: $tuna;
    padding: 16px 35px 16px 16px;
    margin: 2px 15px auto 15px;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    position: relative;
    border-bottom: 2px solid $frenchGrey;
    font-size: $largeText;
    text-decoration: none;

    span {
      font-weight: normal;
      display: inline-block;
      margin-left: 8px;
    }

    &--collection {
      color: $trout;
      background-color: $porcelain;
      margin: 0 0 0 15px;
    }
  }

  &:first-of-type {
    .vocab-dropdown__parent {
      border-top: 1px solid $medium-grey;
    }
  }

  &__arrow-dropdown {
    text-align: right;
    position: absolute;
    // display: block;
    top: 20px;
    height: 13px;
    right: 20px;
    transition: transform 0.25s ease-in-out;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid $tuna;

    // &::before,
    // &::after {
    //   position: absolute;
    // display: block;
    // content: '';
    // border: 9px solid transparent;
    // }

    &.active {
      transform: rotate(90deg);
    }

    &::before {
      top: 45px;
      border-top-color: $astronaut;
    }

    &::after {
      top: 22px;
      border-top-color: $geyser;
    }

    &--collection {
      &::before {
        top: -32px;
      }

      &::after {
        top: -35px;
        border-top-color: $porcelain;
      }
    }
  }

  &__inner {
    max-height: 0;
    width: 95%;
    padding: 0 15px;
    overflow-x: auto;
    transition: max-height 0.1s ease-in;

    &--collection {
      width: auto;

      li {
        background-color: $alabaster;
        margin-bottom: 5px;
      }
    }

    &.active {
      max-height: 100%;
      margin-top: 5px;
      padding: 0 3px 0 15px;
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
