.mcp {
  display: flex;
  flex-direction: column;

  &__summarybar {
    width: 100%;
    min-height: $filterBarHeight;
    background-color: $astronaut;
    display: flex;
    flex-direction: column;
    transition: max-height 0.5s ease-in;
    overflow: hidden;
    padding: 10px 30px 30px;

    &__title {
      width: 5%;
      display: inline-block;
      font-size: $largeText;
      color: $white;
      margin: 0 0 20px;
    }

    &__summary {
      width: 100%;
      display: inline-block;
      line-height: 24px;
      margin: 0;
      font-size: $mediumText;
      color: $alabaster;
      max-height: 200px;
      overflow: auto;

      /* scroll styles not currently working... */
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      p {
        max-width: 100%;
        margin: 0;
      }

      a {
        color: $white;
      }
    }
  }

  &__toprow {
    display: flex;
    flex-direction: column;

    @include breakpoint(tablet) {
      flex-direction: row;
    }
  }

  &__articles,
  &__guides {
    &-container {
      margin: 15px;
      background-color: $white;
      flex: 1;
    }

    &-list {
      display: flex;
      flex-direction: column;
      margin: 15px;
    }

    &__header {
      font-weight: bold;
      display: inline-block;
      margin: 5px 12px 0;
      flex: 1;
      color: $shipCove;

      &--link {
        font-weight: normal;
        float: right;
        color: $tuna;
        text-decoration: underline;
        font-size: $smallText;
      }

      &--analysis {
        color: $cerulean;
      }
    }
  }

  &__regupdates {
    &-lists {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-list {
      flex: 2;
      flex-direction: row;
      display: flex;
    }

    &-sublist {
      flex: 1;
    }

    &__header:not(.mcp__regupdates__header--link) {
      color: $totemPole;
    }
  }

  &__article,
  &__regupdate,
  &__item {
    background-color: $alabaster;
    padding: 10px 20px;
    margin: 13px;
    letter-spacing: 0.5px;
    line-height: normal;
    flex: 1;
    position: relative;
    overflow: hidden;

    &__title {
      font-weight: 600;
      font-size: $largerText;
      margin-bottom: 18px;
      text-decoration: none;
      color: $tuna;

      @include title-hover;
    }

    &__date {
      font-weight: 700;
      font-size: $vSmallText;
      margin-bottom: 0;
    }

    &__content {
      font-weight: normal;
      font-size: $mediumText;
      line-height: 20px;
    }

    &__link {
      text-decoration: none;
      text-transform: capitalize;
      color: $tuna;
    }

    &__published {
      margin-bottom: 18px;
      font-size: $smallText;

      strong {
        color: $shipCove;
      }
    }

    &--analysis {
      strong {
        color: $cerulean;
      }
    }
  }

  &__regupdate {
    margin: 0 13px;

    &:first-child {
      margin-top: 13px;
    }

    &__published {
      strong {
        color: $totemPole;
      }
    }

    &__header {
      margin: 0;
      padding-bottom: 15px;
    }
  }

  &__guides {
    background-color: $mercury;
  }

  &__guides,
  &__regupdates {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__header__container {
    width: 100%;
    margin: 15px 15px 0;
  }

  &__guides-sublist {
    margin: 20px 30px 30px 0;
    align-items: stretch;
    display: flex;
    flex-direction: column;

    .mcp__item {
      padding: 10px;
      border: 0;
      margin: 0;

      &__title {
        margin-bottom: 0;
      }

      &__link {
        font-size: $mediumText;
        line-height: 15px;
        line-height: 25px;
      }

      &__date {
        margin-top: 0;
      }

      &:last-child {
        margin-top: 30px;
      }
    }
  }
}
