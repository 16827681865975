.preloader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.preloader-container {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &--inline {
    height: auto;
    padding: 40px 0;
  }

  .infinite-scroll-component & {
    padding: 0;

    .preloader {
      margin-top: 40px;
    }
  }
}

.preloader span {
  position: absolute;
  border: 4px solid $crimson;
  opacity: 1;
  border-radius: 50%;
  animation: preloader 1s cubic-bezier(0, 0.2, 0.3, 1) infinite;
  margin: 0;
  display: inline-block;
}

.preloader span:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes preloader {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
