.regupdate {
  margin: 30px;
  flex: 3 1;
  border-bottom: 1px solid $geyser;

  @include breakpoint(tablet) {
    margin: 20px 20px 20px 30px;
    border-right: 1px solid $geyser;
    border-bottom: 0;
  }

  &-container {
    display: flex;
    flex-direction: column;

    @include breakpoint(tablet) {
      flex-direction: row;
      padding-top: 10px;
    }

    .tags {
      &__container {
        flex-direction: column;
        border: 0;
      }

      &__title {
        border-bottom: 1px solid $geyser;
        padding-bottom: 10px;
        width: 100%;
        margin: 0 0 15px;
      }
    }
  }

  &__date {
    font-size: $smallText;
    font-weight: normal;
    padding: 20px 0;
    border-top: 1px solid $geyser;
    border-bottom: 1px solid $geyser;
    margin: 0;
  }

  &__title {
    font-size: $largerText;
    margin: 30px 0 10px;

    @include breakpoint(tablet) {
      font-size: $xLargeText;
      margin: 30px 20px 10px 0;
    }
  }

  &__content {
    padding: 30px 0 0;

    @include breakpoint(tablet) {
      padding: 30px 30px 0 0;
    }

    * {
      font-size: $mediumText;

      @include breakpoint(tablet) {
        font-size: $largeText;
      }
    }

    ul {
      padding-left: 20px;
      list-style: disc;
      margin-bottom: 30px;

      li {
        margin-bottom: 12px;
      }
    }

    p {
      line-height: 22px;
      margin: 0 0 30px;
    }

    a[href] {
      color: $cerulean;
    }
  }

  &__timeline {
    &__item {
      background-color: $mercury;
      padding-bottom: 10px;
      letter-spacing: 0.5px;
      line-height: normal;
      flex: 1;
      position: relative;
      overflow: hidden;
    }

    &__header {
      font-weight: bold;
      display: inline-block;
      margin: 5px 12px 0;
      flex: 1;
    }

    &__published {
      font-size: $vSmallText;
      font-weight: normal;
      line-height: 16px;
    }

    &__icondate {
      font-size: 1.5rem;
      font-weight: 600;
      padding: 10px;
      border-top: 1px solid $geyser;
      border-bottom: 1px solid $geyser;
      margin: 0;
      position: relative;
      left: -33px;
      top: -10px;
    }

    &__date {
      font-size: $vSmallText;
      font-weight: normal;
      padding: 20px 0;
      margin: 0;
    }

    &__title {
      font-weight: 800;
      font-size: $smallText;
      margin-top: 5px;
    }

    &__link {
      text-decoration: none;
      text-transform: capitalize;
      color: $tuna;
    }

    &__content {
      padding: 30px 30px 0 0;
    }
  }

  blockquote {
    padding: 40px;
    background-color: $light-grey;

    p {
      font-style: italic;
      font-size: $blockquote;
      line-height: 28px;
      margin: 0;
    }
  }

  &__related {
    padding-right: 5px;
  }

  &.print {
    border-right: 0;

    .page-actions {
      border-bottom: 0;
    }

    h2 {
      width: 90%;
    }
  }
}
