.dates-between {
  margin-bottom: 10px;
  padding: 0 10px;
  display: flex;
  align-items: center;

  &__heading {
    margin: 1em 0;
  }

  &__date-picker {
    width: 20%;
    min-width: 150px;
    padding: 0 10px;
    line-height: 0;
  }

  &__button {
    background-color: $denim;
    height: 25px;
    border-radius: 3px;
    padding: 0 15px;
    font-size: 13px;
    font-weight: bold;
    color: $white;
    transition: color 0.2s ease-in-out;
    margin: 0;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      background-color: $dark-grey;
      opacity: 0.4;
      cursor: initial;
    }

    &--reset {
      margin-left: 5px;
      background-color: $salmon;
    }
  }
}
