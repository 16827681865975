$darker-grey: #787878;

.main-nav {
  background-color: $alabaster;
  flex: 1 1;
  //transition: width 0.5s ease-in;
  overflow: hidden;
  display: none;
  max-width: 270px;
  position: static;
  width: 90%;
  box-shadow: 1px 2px 10px grey;
  top: 95px;

  @include breakpoint(large-mobile) {
    z-index: 10; // does clash with footer, but switching this off breaks the menu for mobile
  }

  @include breakpoint(tablet) {
    // position: static;
    width: $sideBarWidth;
    max-width: $sideBarWidth;
    box-shadow: none;
    top: 10vh;
    z-index: 0;
  }

  @include breakpoint(large-desktop) {
    position: sticky;
    position: -webkit-sticky;
  }

  &.open {
    min-width: $sideBarWidth;
    display: block;

    @include breakpoint(mobile) {
      min-width: 100%;
    }
    @include breakpoint(tablet) {
      min-width: $sideBarWidth;
    }
    @include breakpoint(large-desktop) {
      min-width: $sideBarWidth;
    }

  }

  &.closed {
    width: 0;
    height: 0;
  }

  &__pages {
    background-color: $athensGrey;
    padding: 10px;

    a {
      background: $white no-repeat 12px center / 22px;
      text-decoration: none;
      font-size: 14px;
      display: inline-block;
      width: 100%;
      color: $trout;
      border-radius: 5px;
      font-weight: bold;
      padding: 0;
      line-height: 38px;
      height: 35px;
      position: relative;

      &.active {
        background-color: $crimson;
        color: $white;

        ~ .nested {
          display: block;
        }
      }

      &.nested {
        background-color: $medium-grey;
        color: $darker-grey;
        border: 0;
        border-radius: 0;
        height: 30px;
        padding: 0 15px;
        line-height: 30px;
        text-align: left;
        text-transform: uppercase;
        font-size: 12px;
        display: none;

        &.active {
          background-color: #acadad;
        }
      }
    }

    li {
      text-align: center;
      margin-bottom: 10px;

      /* react router won't allow classnames on NavLinks */
      &:first-child {
        a {
          background-image: url('/images/assets/icons/icon-nav-home.svg');

          &.active {
            background-image: url('/images/assets/icons/icon-nav-home-active.svg');
          }
        }
      }

      &:nth-child(2) {
        a {
          background-image: url('/images/assets/icons/icon-nav-articles.svg');

          &.active {
            background-image: url('/images/assets/icons/icon-nav-articles-active.svg');
          }
        }
      }

      &:nth-child(3) {
        a {
          background-image: url('/images/assets/icons/icon-nav-articles.svg');

          &.active {
            background-image: url('/images/assets/icons/icon-nav-articles-active.svg');
          }
        }
      }

      &:nth-child(4) {
        a {
          background-image: url('/images/assets/icons/icon-nav-guides.svg');

          &.active {
            background-image: url('/images/assets/icons/icon-nav-guides-active.svg');
          }
        }
      }

      &:nth-child(5) {
        a {
          background-image: url('/images/assets/icons/icon-nav-requirements.svg');

          &.active {
            background-image: url('/images/assets/icons/icon-nav-requirements-active.svg');
          }
        }
      }

      //&:nth-child(6) {
      //  a {
      //    background-image: url('/images/assets/icons/icon-nav-rules.svg');
      //
      //    &.active {
      //      background-image: url('/images/assets/icons/icon-nav-rules-active.svg');
      //    }
      //  }
      //}

      &:nth-child(6) {
        a {
          background-image: url('/images/assets/icons/icon-nav-regupdates.svg');

          &.active {
            background-image: url('/images/assets/icons/icon-nav-regupdates-active.svg');
          }
        }
      }

      &:last-child {
        margin: 0;

        a {
          background-image: url('/images/assets/icons/icon-nav-trackers.svg');

          &.active {
            background-image: url('/images/assets/icons/icon-nav-trackers-active.svg');
          }
        }
      }
    }

    a:not(:only-child):first-of-type {
      &::after {
        content: '';
        text-align: right;
        position: absolute;
        top: 13px;
        right: 15px;
        //transition: transform 0.3s ease-in-out;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid $tuna;

        &.active {
          transform: rotate(90deg);
        }
      }
    }
  }
}
