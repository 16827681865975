.siema {
  position: relative;

  .home__article {
    &--home-carousel {
      background: $light-grey;
      margin: 0 12px 0;
      height: 330px;
      padding: 23px 33px;

      @include breakpoint(tablet) {
        height: 370px;
      }
    }

    &__title {
      // font-size: $vLargeText;
      font-size: $headlineText;
      text-decoration: none;
      color: $tuna;
      max-height: 90px;
      overflow: hidden;
      display: block;

      // increased from 2 to 3
      @include ellipsis(3);
      @include title-hover;

      @include breakpoint(tablet) {
        // font-size: $xLargeText;
        font-size: $headlineText;
      }
    }

    &__date {
      margin-bottom: 22px;

      span {
        color: #8a8d9a;
        font-weight: normal;
      }
    }

    &__link {
      text-decoration: underline;
    }
  }

  &-dots {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;

    button {
      background: transparent;
      padding: 0 5px;
      margin: 0 4px;
      color: inherit;
      cursor: pointer;
    }
  }

  &-dot {
    color: #aaa;

    &.active {
      color: #333;
    }
  }
}
