ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

label {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.form {
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: 0;
  }

  label {
    display: none;
  }

  li {
    position: relative;
  }

  &__text-area {
    flex-direction: column;
  }

  &__field {
    width: 100%;
    padding: 15px;
    border-radius: 3px;
    border: 0;
    background: $alabaster;
    border-bottom: 1px solid $gallery;
    outline: 0;
    margin-bottom: 20px;

    &::placeholder {
      color: $silver;
    }
  }

  &__checkbox {
    display: flex;
    width: auto;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 15px;
    background: #fafafa;
    margin: 0 0 20px;
    align-items: flex-start;

    &[type='checkbox'] {
      margin: 2px 0 0;
    }

    label {
      margin: 0 0 0 10px;
      display: block;
    }
  }

  &__success {
    &__message {
      color: $green;
      padding: 5px;
    }
  }

  &__error {
    &__message {
      color: $error;
      padding: 5px;
      margin: 0 0 6px;
    }
  }

  &.contact__form {
    .form__error__message {
      position: static;
      margin: 0 0 2px;
      right: auto;
      top: auto;
      white-space: pre;
    }
  }
}

// To handle table styling inside content
table {
  border: $table-border;

  td,
  th {
    border: $table-border;
  }
}

.vixio {
  &__logo {
    background: transparent url('/images/assets/logo-payments.svg') no-repeat left center / cover;
    height: 50px;
    min-height: 50px;
    width: 50px;
    min-width: 50px;
    display: inline-block;
    font-size: 0;

    @include breakpoint(tablet) {
      width: 240px;
      height: 70px;
      min-height: 70px;
      margin-left: 10px;
      background-size: contain;
    }
  }
}

button {
  border: 0;
}

.button {
  margin-top: 20px;
  border: 0;
  min-height: 36px;

  &--primary {
    background-color: $tuna;
    color: $white;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
  }

  &--download {
    background: $tuna url('/images/assets/icons/icon-download.svg') no-repeat right 5px center / 25px;
    padding-right: 40px;
    display: none;

    &-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @include breakpoint(desktop) {
      display: block;
    }
  }

  &--white {
    background-color: $white;
    color: $tuna;
    border: 1px solid $iron;
  }

  &--red {
    background-color: #e1251b;
    color: $white;
    border: 0;
    margin: 0;
    text-decoration: none;
    padding: 8px 30px 8px 10px;
    display: inline-block;
    position: relative;

    &::after {
      content: '›';
      width: 10px;
      height: 10px;
      position: absolute;
      right: 5px;
      font-size: 2rem;
      top: 4px;
    }
  }

  &--small {
    padding: 4px 8px;
  }

  &--link {
    padding: 4px 8px;
    background: none;
    border: 0;
    cursor: pointer;
    color: $crimson;
    display: inline;
    font-size: $mediumText;

    &:hover {
      text-decoration: underline;
    }

    &:disabled {
      color: $mercury;
      cursor: not-allowed;
    }
  }

  &--plain {
    text-decoration: none !important;
    font-size: 1.5rem;
  }

  &:disabled {
    background-color: $dark-grey;
    color: $mercury;
    cursor: not-allowed;
  }

  &--text {
    appearance: none;
    background: transparent;
    cursor: pointer;
    // This should really be on the base component or on some size variant,
    // the fact that every button variant has different sizes is braindead.
    padding: 0 5px;

    &:focus {
      outline: none;
    }
  }
}

.link {
  color: $tuna;
  cursor: pointer;
  text-decoration: underline;
}

#infinite-scroll-container,
#infinite-scroll-container2 {
  min-width: 250px;
  height: 70vh;
  overflow: auto;
  border-bottom: 1px solid $mercury;

  @include breakpoint(desktop) {
    min-width: 300px;
  }
}

.infinite-scroll-component {
  &__message {
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding: 50px;
  }
}

.theme-pc {
  .icon-padlock {
    vertical-align: middle;
    margin-right: 5px;
    width: 20px;
    display: inline-block;
  }
}

.unauthorised {
  filter: blur(4px);
  pointer-events: none;
}

.message {
  &__success,
  &__error {
    padding: 10px;
  }

  &__success {
    margin: 0;
    padding: 10px 8px 10px 32px;
    color: $crimson;

    &::after {
      content: '';
      display: block;
      width: 5px;
      height: 14px;
      border: solid $crimson;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: absolute;
      left: 15px;
      top: 9px;
    }
  }

  &__error {
    background-color: $salmon;
    border: 1px solid $guardsman;
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 45px;
  height: 45px;
  z-index: 3;
  color: $white;
  box-shadow: -2px 2px 4px $santasGrey;
  border-radius: 5px;
  cursor: pointer;
  font-size: $largeText;
  display: none;
  transform: rotate(-90deg);
  background: $iron url(/images/assets/icons/icon-chevron.svg) no-repeat center center / 34px;

  &:hover {
    background-color: $gallery;
  }

  &.active {
    display: block;
  }

  @include breakpoint(tablet) {
    width: 60px;
    height: 60px;
  }
}

.page-description {
  background-color: #F1F1F1;
  padding: 5px 30px;
  margin: 0 0 20px 0;
  letter-spacing: 0.5px;
  line-height: normal;
  width: 100%;
  min-height: 70px;
  font-size: 18px;

  &.nomargin {
    margin: 0;
  }
}

.tooltip {
  max-width: 300px;
}
