.rules {
  display: flex;
  width: 100%;
  flex-direction: column;

  .message {
    font-weight: bold;
    color: #383b41;
    font-size: 1.8rem;
  }

  @include breakpoint(tablet) {
    flex-direction: row;
  }

  .rules__browser {
    background-color: #d7dde3;
    flex: 1 1;

    @include breakpoint(tablet) {
      min-width: 420px;
      max-width: 420px;
    }

    li.jurisdiction {
      .jurisdiction-name {
        display: flex;
        font-weight: bold;
        color: #383b41;
        padding: 16px 35px 16px 16px;
        margin: 2px 15px auto 15px;
        line-height: normal;
        text-transform: capitalize;
        cursor: pointer;
        position: relative;
        border-bottom: 2px solid #c9cad0;
        font-size: 1.8rem;
        text-decoration: none;
      }

      .rule-list {
        max-height: 100%;
        margin-top: 5px;
        padding: 0 3px 0 15px;
        overflow: hidden;
      }

      a {
        margin: 2px auto auto 15px;
        font-weight: bold;
        padding: 15px;
        text-transform: capitalize;
        cursor: pointer;
        position: relative;
        display: block;
        text-decoration: none;
        font-size: 1.8rem;
        background-color: #e1e6e8;
        border-bottom: 2px solid #c9cad0;
        color: #4a4e60;
      }
    }
  }

  .rulebook {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    .tree {
      width: 300px;
      padding-left: 10px;
      padding-right: 30px;
      padding-top: 10px;

      @include breakpoint(tablet) {
        min-width: 300px;
        max-width: 400px;
      }

      li {
        margin-left: 15px;
      }

      > li {
        margin-left: 0;
      }

      .active {
        font-weight: bold;
      }

      .label {
        padding-bottom: 5px;
        cursor: pointer;
      }
    }

    .rule-container {
      width: 100%;
      padding: 10px;

      h2 {
        margin: 0;
      }
    }
  }
}
