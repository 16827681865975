.quickfilter {
  padding-top: 24px;

  &__activeFilters {
    background-color: $light-grey;
    padding: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
    min-height: 150px;
    max-height: 350px;
    overflow: auto;
    border: 1px solid $gallery;

    &__section {
      margin-bottom: 10px;
    }

    &__vocab {
      color: $trout;
      text-transform: capitalize;
      font-size: 1.3rem;
      margin-bottom: 6px;
      padding-left: 2px;
    }

    &__tag {
      background-color: $iron;
      border-radius: 5px;
      padding: 0 0 0 7px;
      font-size: $vSmallText;
      font-weight: bold;
      text-decoration: none;
      color: $trout;
      margin: 0 10px 10px 0;
      display: inline-flex;
      align-items: center;

      &__remove {
        background-color: $crimson;
        color: $white;
        padding: 6px;
        margin-left: 10px;
        border-radius: 0 5px 5px 0;
        cursor: pointer;
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  button {
    margin-top: 15px;
    float: right;
  }

  label {
    color: $trout;
    display: block;
  }

  p {
    color: $trout;
  }

  &__error {
    margin: 2px 0 6px;
    color: $error;
    text-align: right;
    min-height: 16px;
  }
}
