.page-actions {
  display: flex;
  padding: 10px 10px 10px 0;
  border-top: 1px solid $iron;
  border-bottom: 1px solid $iron;
  margin: 0;
  align-items: center;

  .button {
    margin: 0;
  }

  > *:not(:first-child) {
    margin-left: 10px;
  }

  &__section {
    display: inline-block;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__section__date {
    display: block;
  }

  &__date-published {
    font-size: $smallText;
    font-weight: bold;
    margin-right: auto;
  }

  strong {
    color: $totemPole;
  }
}

.article {
  .page-actions {
    strong,
    a {
      color: $cerulean;
    }
  }
}

.guides {
  .page-actions strong {
    color: $astronaut;
  }
}

.compliance__header--insights {
  ~ .compliance__content__container {
    .article {
      .page-actions {
        strong,
        a {
          color: $shipCove;
        }
      }
    }
  }
}
