.account-options {
  position: relative;
  display: flex;
  z-index: 9;

  &__icon {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 12px;
    border: 3px solid $crimson;
    color: $crimson;
    cursor: pointer;
    letter-spacing: -1px;
    line-height: 1;
  }

  &__omega {
    font-size: 1.8rem;
  }

  &__selector {
    position: absolute;
    padding: 8px;
    width: 195px;
    top: 54px;
    right: -10px;
    flex-direction: column;
    background-color: $white;
    border-radius: 3px;
    box-shadow: 1px 3px 12px $dark-grey;
    align-items: center;
    display: none;
    opacity: 0;
    transition: all 0.5s linear;
    z-index: 3;

    &--active {
      display: block;
      opacity: 1;
    }

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 15px solid $white;
      top: -13px;
      right: 21px;
    }

    &__item {
      line-height: normal;
      margin-top: 6px;

      &:first-child {
        margin: 0;
      }

      &__option {
        width: 100%;
        display: inline-block;
        text-align: center;
        height: 30px;
        font-size: $vSmallText;
        font-weight: bold;
        color: $white;
        text-decoration: none;
        line-height: normal;
        letter-spacing: 0.5px;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .button {
      font-size: $vSmallText;
      font-weight: bold;
      letter-spacing: 0.5px;
      width: 100%;
      margin: 0;
      display: inline-block;
      text-decoration: none;
      text-align: center;
      margin-top: 7px;
      border: 0;

      &:first-child {
        margin: 0;
        height: 30px;
        border: 1px solid $frenchGrey;
      }
    }
  }
}
