.vocab-dropdown {
  height: auto;

  &__parent {
    font-weight: bold;
    color: #35383f;
    line-height: 35px;
    text-transform: capitalize;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid $gallery;
    font-size: $smallText;
    padding-left: 10px;
    width: 100%;

    span {
      font-weight: normal;
    }
  }

  &__arrow-dropdown {
    text-align: right;
    position: absolute;
    top: 13px;
    right: 15px;
    transition: transform 0.4s ease-in-out;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid $tuna;

    &.active {
      transform: rotate(90deg);
    }
  }

  &__inner {
    max-height: 0;
    width: 95%;
    padding: 0 8px 0 15px;
    overflow-x: auto;
    transition: max-height 0.4s ease-in;
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &.active {
      max-height: 300px;
    }

    > p {
      margin-top: 0;
    }
  }
}
