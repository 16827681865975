.quick-links {
  margin: 12px 14px 0;
  display: flex;
  flex-direction: column;
  min-height: 0;
  width: calc(100% - 28px);

  @include breakpoint(tablet) {
    max-width: 260px;
    min-width: 260px;
  }

  @include breakpoint(desktop) {
    max-width: 400px;
    min-width: 400px;
  }

  &__header {
    background-color: $silver;
    padding: 13px 20px;
    margin: 0;
    color: #fff;
    font-size: $mediumText;
  }

  &__list {
    margin: 0;
    padding: 20px;
    flex: 1 0 0;
    background-color: $athensGrey;
  }

  &__item {
    padding: 15px 0 8px 0;

    a {
      color: $trout;
      font-size: $smallText;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: inline-block;
      font-weight: bold;
      text-decoration: none;

      // @include breakpoint(tablet) {
      //   width: 360px;
      // }

      &:hover {
        color: #777;
      }
    }
  }
}
