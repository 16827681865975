.myFavourites {
  padding: 0 20px;

  &__quickFilter {
    &:not(:last-of-type) {
      margin-bottom: 50px;
    }

    &__filters {
      background-color: #fafafa;
      padding: 0 10px 10px;
      display: flex;
      flex-wrap: wrap;
      height: 150px;
      overflow: auto;
    }
  }
}
