.filtercheckbox__filter {
  position: relative;

  > .filtercheckbox__filter {
    padding-left: 20px;
  }

  label {
    display: flex;
    margin: 5px 0;
    text-transform: capitalize;
    line-height: 1;
    position: relative;
    max-width: 196px;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
  }

  &__count {
    color: $santasGrey;
    margin-left: 3px;
  }

  &__arrow {
    &.visible {
      text-align: right;
      position: absolute;
      top: 2px;
      right: 0;
      transition: transform 0.3s ease-in-out;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid $tuna;
      cursor: pointer;

      &.active {
        transform: rotate(90deg);
      }
    }
  }

  input[type='checkbox'] {
    margin-right: 5px;
    padding: 0 5px;
    border-radius: 2px;
    -webkit-appearance: none;
    border: 2px solid $medium-grey;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    outline: none;

    &:checked {
      background-color: $silverSand;
      border-color: $silverSand;

      &::after {
        content: '';
        display: block;
        width: 6px;
        height: 12px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        margin-top: 1px;
        transform: rotate(45deg);
      }
    }

    &:indeterminate {
      background-color: $silverSand;
      border-color: $silverSand;

      &::after {
        content: '';
        border: solid $white;
        border-width: 0 2px 2px 0;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 0;
      }
    }
  }

  &.disabled {
    color: $geyser;
  }
}
