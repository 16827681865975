.trackers {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 15px;
  }

  &-list {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;

    @include breakpoint(tablet) {
      flex-direction: row;
    }
  }

  &__item {
    background-color: $light-grey;
    padding: 20px;
    margin: 13px;
    letter-spacing: 0.5px;
    line-height: normal;
    flex: 1;

    @include breakpoint(tablet) {
      flex: 1 30%;
    }

    &__summary {
      margin: 15px 0 0;
    }

    &__title {
      max-height: 90px;
      font-weight: 600;
      font-size: $largeText;
      line-height: 25px;
      text-decoration: none;
      color: $tuna;

      @include title-hover;
    }

    &__updated {
      max-height: 90px;
      font-weight: 600;
      font-size: $smallText;
      display: inline-flex;
      vertical-align: sub;
    }

    &__date {
      color: $cerulean;
    }
  }
}
