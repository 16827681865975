.view-icon {
  margin: 0 2%;
  height: 32px;

  img {
    height: 32px;
    cursor: pointer;

    &.active {
      filter: brightness(0) invert(1);
    }

    &:not(.active):hover {
      opacity: 0.6;
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .view-icon {
    img {
      &.active {
        opacity: 0.4;
      }
    }
  }
}
