.searchbar {
  position: relative;

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;
    justify-content: flex-end;

    @include breakpoint(tablet) {
      width: 330px;
    }

    @include breakpoint(desktop) {
      width: 530px;
    }
  }

  &__submit {
    width: 35px;
    height: 35px;
    outline: none;
    margin: -3px 0 0;
    background: $white url('/images/assets/icons/icon-search.svg') no-repeat
      right 5px center / 28px;
    cursor: pointer;

    @include breakpoint(tablet) {
      margin-left: 12px;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &__dropdownwrap {
    display: none;
    position: relative;

    &.active {
      display: block;
    }
  }

  &__dropdown,
  &__autocomplete {
    background-color: $white;
    z-index: 1;
    height: auto;
    width: calc(100% - 46px);
    display: none;
    border: 1px solid #d2d2d1;
    box-shadow: 2px 2px 3px $medium-grey;

    &--active {
      display: block;
    }

    p {
      font-size: 13px;
      margin-left: 5%;
      text-decoration: underline;
      text-transform: capitalize;
      cursor: pointer;
    }

    &__header {
      padding: 10px 20px;
      margin: 0;
      font-weight: normal;
      display: flex;
    }

    &__recent-searches {
      background-color: $alabaster;
    }

    &__recent {
      text-align: left;
      padding: 0 15px 15px 50px;
      margin: 0;
      color: #a0a0a0;

      &-item {
        padding: 5px 0;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__quicklinks {
      text-align: left;
      padding: 15px 15px 10px;
      margin: 0;
      background-color: #ebebeb;
      color: #a0a0a0;
    }
  }

  &__dropdown {
    position: absolute;
    top: 32px;
    left: 12px;
    border: 0;
    color: $trout;
  }

  &__autocomplete {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 0;
    box-shadow: none;

    &__item {
      padding: 3px 0;
      cursor: pointer;
    }
  }

  &__dropdown--active {
    .searchbar__autocomplete {
      padding: 10px 18px;
    }
  }
}
