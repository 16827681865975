.filterbar {
  max-height: $filterBarHeight;
  background-color: $filterGrey;
  display: flex;
  overflow: hidden;
  justify-content: space-evenly;

  @include breakpoint(tablet) {
    position: sticky;
    position: -webkit-sticky;
    top: 10vh;
    z-index: 2;
  }

  > [class^='filterbar']:not(.filterbar__view) {
    padding: 12px;
  }

  &.expanded {
    max-height: 100%;
  }

  @include breakpoint(tablet) {
    justify-content: unset;
  }

  &__tagtoggle {
    display: flex;
    line-height: 26px;
    color: #fff;
    margin: 0 15px;

    &__wrapper{
      margin: 0;
      width: 60px;
      display: inline-flex;
    }
    &__text {
      display: inline-block;
      line-height: 26px;
      color: $white;
      margin: 0 15px;
    }

    &.hidden {
      display: none;
    }

  }

  &__filter-text {
    color: $gallery;
    margin: 0 0 0 15px;
    font-size: 1.6rem;
    flex: 0.5;
    max-width: 100px;
    align-self: flex-start;
    line-height: 26px;

    @include breakpoint(tablet) {
      margin: 0 15px;
    }
  }

  &__selected-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex: 12;

    span {
      color: $gallery;
    }
  }

  &.extended {
    width: 78%;
  }

  &__expand {
    margin: 0;
    line-height: 30px;
    flex: 0.5;
    width: 65px;
    min-width: 65px;

    @include breakpoint(tablet) {
      width: 78px;
      min-width: 78px;
    }

    > p {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__button {
    display: flex;
    flex: 1;
    align-items: center;

    &__clear {
      margin-left: 5px;
    }

    &__star {
      &__icon {
        line-height: 0;
        font-size: $mediumText;
        display: flex;

        &--active {
          color: $crimson;

          &:hover {
            color: rgba($crimson, 0.7);
          }
        }
      }
    }

    button {
      background-color: $white;
      height: 24px;
      border-radius: 5px;
      padding: 0 15px;
      font-size: 13px;
      font-weight: bold;
      color: $shuttle-grey;
      transition: color 0.2s ease-in-out;
      margin: 0;
      cursor: pointer;

      &:last-child {
        margin-left: 10px;
      }

      &:hover {
        color: $silverSand;
      }

      &:disabled {
        color: $silverSand;
        cursor: default;
      }
    }
  }

  &__view {
    align-items: center;
    min-width: 215px;
    padding: 12px 0;
    display: none;

    @include breakpoint(tablet) {
      display: flex;
    }

    &__text {
      display: inline-block;
      line-height: 26px;
      color: $white;
      margin: 0 15px;
    }

    &.hidden {
      display: none;
    }

    > span {
      color: $white;
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .filterbar__selected-filters {
    flex: 7 1;
  }
}
