.quick-filters-nav {
  padding: 30px 15px;
  background-color: white;

  &__header {
    border-bottom: 1px solid $gallery;
    font-weight: bold;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    &__star {
      position: relative;
      height: 25px;
      line-height: 25px;
      display: inline-block;
      align-items: center;
      justify-content: space-around;
      color: $tuna;
    }

    &__text {
      margin-left: 5px;
      font-size: $smallerText;
    }

    a {
      color: $crimson;
      font-size: 10px;
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }
}
