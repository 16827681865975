.article {
  margin: 30px;
  flex: 3 1;
  border-bottom: 1px solid $geyser;

  @include breakpoint(tablet) {
    margin: 20px 20px 20px 30px;
    border-right: 1px solid $geyser;
    border-bottom: 0;
  }

  &-container {
    display: flex;
    flex-direction: column;

    @include breakpoint(tablet) {
      flex-direction: row;
      padding: 10px 10px 10px 0;
    }

    .tags {
      &__container {
        flex-direction: column;
        border: 0;
      }

      &__title {
        border-bottom: 1px solid $geyser;
        padding-bottom: 10px;
        width: 100%;
        margin: 0 0 15px;
      }
    }
  }

  &__header {
    display: none;
  }

  &__title {
    font-size: $largerText;
    margin: 30px 0 10px;

    @include breakpoint(tablet) {
      font-size: $xLargeText;
      margin: 30px 20px 10px 0;
    }
  }

  &__content {
    padding: 20px 0 0;

    @include breakpoint(tablet) {
      padding: 30px 30px 0 0;
    }

    * {
      font-size: $mediumText;

      @include breakpoint(tablet) {
        font-size: $largeText;
      }
    }

    ul {
      padding-left: 20px;
      list-style: disc;
      margin-bottom: 30px;

      li {
        margin-bottom: 12px;
      }
    }

    p {
      line-height: 22px;
      margin: 0 0 30px;
    }

    a[href] {
      color: $cerulean;
    }
  }

  blockquote {
    padding: 40px;
    background-color: $light-grey;
    margin: 0 0 40px;

    @include breakpoint(tablet) {
      margin: 40px;
    }

    p {
      font-style: italic;
      font-size: $blockquote;
      line-height: 28px;
      margin: 0;
      color: $cerulean;
    }
  }

  &__related {
    padding: 0;
    margin: 0 30px 30px;

    @include breakpoint(tablet) {
      margin: 20px 20px 20px 0;
    }

    @include breakpoint(desktop) {
      border-right: 0;
      padding-right: 0;
    }

    .tags__title {
      margin: 0 0 15px;
      border-bottom: 1px solid $geyser;
      padding-bottom: 11px;
    }
  }

  &.print {
    border-right: 0;

    .page-actions {
      border-bottom: 0;
    }

    h2 {
      width: 90%;
    }
  }
}

.compliance__header--insights {
  ~ .compliance__content__container {
    .page-actions {
      strong {
        color: $shipCove;
      }
    }

    .article__content {
      a[href],
      blockquote p {
        color: $shipCove;
      }
    }
  }
}
