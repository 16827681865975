.bookmark-button {
  text-align: right;

  &__button {
    cursor: pointer;
    background-color: transparent;
    color: $tuna;
    padding: 7px;

    &:hover {
      background-color: #ececec;
      border-radius: 5px;
    }
  }
}
