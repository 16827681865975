.compliance__menu {
  display: flex;
  width: 100%;
  background-color: $white;
  align-items: center;
  height: 10vh;
  min-height: 95px;
  
  @include breakpoint(tablet) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 10;
  }

  &__burger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 34px;
    text-align: center;
    padding: 10px 20px 10px 15px;
    cursor: pointer;
    border-right: 2px solid #768692;
    margin-right: 10px;
    width: 60px;
    height: 60px;
    outline: none;

    @include breakpoint(tablet) {
      margin-right: 20px;
      padding: 10px 20px 10px 25px;
      width: 88px;
    }

    &__bar {
      width: 30px;
      margin: 3px 0;
      height: 4px;
      background-color: #768692;

      @include breakpoint(tablet) {
        width: 40px;
      }
    }

    &__close {
      display: none;
      width: 26px;
      height: 26px;
      color: #768692;
      line-height: 0;
    }

    $c: &;

    &.active {
      #{$c} {
        &__bar {
          display: none;
        }

        &__close {
          display: block;
        }
      }
    }
  }

  &__title {
    font-size: 2.8rem;
  }

  &__rhs {
    margin-left: auto;
    margin-right: 25px;
    display: flex;
    align-items: center;

    @include breakpoint(tablet) {
      margin-right: 50px;
    }

    &__icon {
      padding: 10px;
      margin: 0;
      line-height: 15px;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
    }

    &__search {
      margin-right: 2px;

      @include breakpoint(tablet) {
        margin-right: 25px;
      }
    }
  }

  &__form-link {
    font-size: $largeText;
    float: right;
    cursor: pointer;
    margin: 0 30px 0 0;
  }
}

@supports (-ms-ime-align: auto) {
  .compliance__menu__rhs__search {
    margin-right: 55px;
  }
}
