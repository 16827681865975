.tags {
  &__container {
    padding-top: 10px;

    &:last-of-type {
      margin-bottom: 40px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: $smallerText;
    margin: 5px 16px 0 0;
    align-self: self-start;
    flex: 0;
    text-transform: capitalize;

    .article__related & {
      font-size: $mediumText;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-self: flex-start;
  }

  &__item {
    margin: 0 10px 15px 0;
    display: inline-flex;

    &--last {
      margin-right: 30px;
    }
  }

  &__tag {
    background-color: $iron;
    border-radius: 5px;
    padding: 7px;
    font-size: $vSmallText;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: $trout;

    &:hover {
      background-color: $gallery;
    }
  }
}
