.search-results {
  &__container {
    padding: 25px;

    @include breakpoint(tablet) {
      padding: 40px;
    }
  }

  &__search {
    position: relative;
    display: inline-block;
  }

  &__input {
    height: 30px;
    padding: 5px 5px 5px 20px;
    margin-right: 10px;
    border-radius: 14px 0 0 14px;
    border: 0;
    font-size: 12px;
    color: $mineshaft;
    background-color: $gallery;
    outline: 0;
    width: 75%;

    &::placeholder {
      color: $trout;
    }

    @include breakpoint(tablet) {
      width: 400px;
      max-width: 400px;
    }

    @include breakpoint(desktop) {
      width: 530px;
      max-width: 530px;
    }
  }

  &__clear {
    border: 0;
    outline: 0;
    position: absolute;
    width: 0;
    height: 0;
    background-color: $white;

    &::after {
      content: 'x';
      background-color: $dark-grey;
      color: $white;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 35px;
      top: 8px;
      cursor: pointer;
      font-size: $smallText;

      @include breakpoint(tablet) {
        right: 25px;
      }
    }
  }

  &__tabs {
    display: flex;
    margin-top: 20px;
    align-items: flex-end;
    overflow: auto;

    &__item {
      padding: 12px;
      letter-spacing: 0.5px;
      position: relative;
      cursor: pointer;
      outline: 0;
      white-space: nowrap;

      @include breakpoint(tablet) {
        padding: 15px 20px;
      }

      &::after {
        content: '';
        position: absolute;
        height: 3px;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: $gallery;
      }

      &__subtotal {
        font-weight: normal;
      }
    }

    &__item--active {
      font-weight: 600;

      &::after {
        content: '';
        background-color: $crimson;
      }
    }
  }

  &__total {
    margin: 25px 0 0;
    padding: 0 0 10px 10px;
    font-size: $smallerText;

    span {
      color: $crimson;
    }
  }

  &__sort {
    display: flex;
    padding: 10px;
    margin-top: 4px;

    &__type {
      font-size: $smallText;
      cursor: pointer;
      background-color: $alabaster;
      color: $trout;

      &--active {
        font-weight: bold;
        background-color: $iron;
      }

      &:first-child {
        padding-right: 10px;
        margin-right: 10px;
      }
    }
  }

  &__results {
    margin-top: 20px;
  }

  &__result {
    background-color: $alabaster;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 20px;

    &__published {
      font-size: $smallText;
      font-weight: bold;

      strong,
      a {
        color: $crimson;
      }
    }

    &__header {
      margin: 10px 0 0;
      font-size: $largeText;
    }

    &__summary {
      line-height: 20px;
      font-size: $mediumText;
      margin-bottom: 0;

      @include ellipsis(2);
    }

    &__link {
      margin-top: 15px;
      color: inherit;
      text-decoration: none;

      @include title-hover;
    }
  }
}
