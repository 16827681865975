.individual-quick-filter {
  margin: 10px 0;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}
