.author__details {
  width: 100%;
  background-color: $astronaut;
  display: inline-block;
  line-height: 24px;
  margin: 0;
  font-size: $mediumText;
  color: $alabaster;
  overflow: auto;
  padding: 20px 30px;

  li {
    color: $alabaster;
  }

  + .mcp__container {
    #infinite-scroll-container,
    #infinite-scroll-container2 {
      @include breakpoint(tablet) {
        flex: 1;
      }
    }
  }
}
