/* colours */
$white: #fff;
$black: #000;
$tuna: #383b41;
$trout: #494d60;
$shuttle-grey: #5f6373;
$light-grey: #fafafa;
$medium-grey: #d9d9d9;
$dark-grey: #bec1c6;
$silverSand: #bec1c6;
$mercury: #e8e7e7;
$geyser: #d7dee3;
$error: #c00;
$iron: #ddd;
$filterGrey: #868585;
$silver: #a4a4a4;
$denim: #1d71b8;
$azure: #3064ac;
$shipCove: #6982b0;
$trout: #4a4e60;
$cerulean: #009fe3;
$alabaster: #fafafa;
$astronaut: #293352;
$dusty: #9aa5af;
$iron: #e2e2e5;
$athensGrey: #f0f0f1;
$gallery: #ececec;
$santasGrey: #a1a3ac;
$geyser: #d7dde3;
$frenchGrey: #c9cad0;
$porcelain: #e1e6e8;
$mineshaft: black;
$totemPole: #a89968;
$peppermint: #dff0d8;
$moss: #c7e4bb;
$salmon: #f77;
$guardsman: #c00;
$chatamsBlue: #12526e;
$regUpdateAlert: #9d0d05;
$crimson: #da291c;
$green: #28a745;


/* sizes */
$xSmallText: 0.8rem;
$vSmallText: 1rem;
$smallText: 1.2rem;
$smallerText: 1.3rem;
$mediumText: 1.5rem;
$largeText: 1.8rem;
$largerText: 2.1rem;
$vLargeText: 2.8rem;
$xLargeText: 3.6rem;
$blockquote: 2.2rem;

/* alternative sizes - see ticket PAYM-8 */
$headlineText: 2vw;

/* dimensions */
$filterBarHeight: 48px;
$sideBarWidth: 270px;
$btn-width: 60px;

$theme-pc: (
  compliance__menu: (
    bg: $white,
    color: #1f2a44
  ),
  compliance__search: (
    bg: $denim,
    color: $white
  ),
  compliance__header: (
    bg: $white,
    color: $white
  ),
  filterbar: (
    bg: $dusty,
    color: $white
  ),
  quick-links__header: (
    bg: $crimson,
    color: $white
  )
);

/* misc */
$table-border: #d3d3d3 1px dotted;
