.individual-filter {
  display: inline-block;
  margin-bottom: 2px;

  @include breakpoint(tablet) {
    line-height: 26px;
  }

  &__close {
    border: 1px solid $santasGrey;
    padding: 2px 3px 1px;
    font-size: 8px;
    margin: 0 12px 0 7px;
    cursor: pointer;
  }

  &__text {
    opacity: 0.7;
    font-size: $smallerText;
    color: $santasGrey;
    vertical-align: middle;

    &.active {
      opacity: 1;
    }
  }
}
