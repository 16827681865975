.date-picker {
  display: flex;
  border-radius: 3px;
  padding: 2px 10px;
  border: 1px solid $silverSand;

  &--focused {
    outline: $shipCove auto 1px;
  }

  input {
    width: 100%;
    border: 0;

    &:focus {
      outline: none;
    }
  }

  .reset-button {
    cursor: pointer;
    margin-right: -5px;
    color: $shuttle-grey;
    appearance: none;
    background: transparent;
  }
}

// stylelint-disable selector-type-no-unknown
reach-portal {
  position: static;
}

.rdt {
  &Picker {
    border-radius: 3px;
    margin: 12px -10px;
    position: relative;
    z-index: 100;
    width: 250px;
    padding: 4px;
    background: $white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid $medium-grey;
    line-height: normal;

    table {
      table-layout: fixed;
      width: 100%;
      margin: 0;
    }

    td,
    th {
      height: 28px;
      text-align: center;

      &:not(.rdtDisabled) {
        cursor: pointer;

        &:hover {
          background: #eee;
        }
      }
    }

    td {
      &.rdtOld,
      &.rdtNew,
      &.rdtDisabled {
        color: $filterGrey;
      }

      &.rdtDisabled {
        cursor: not-allowed;
      }

      &.rdtToday::before {
        content: '';
        display: inline-block;
        border-left: 7px solid transparent;
        border-bottom: 7px solid $shipCove;
        border-top-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        bottom: 4px;
        right: 4px;
      }

      &.rdtActive {
        &,
        &:hover {
          background-color: $shipCove;
          color: $white;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
