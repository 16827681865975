@mixin breakpoint($media) {
  @if $media == mobile {
    @media only screen and (min-width: 360px) {
      @content;
    }
  }

  @if $media == large-mobile {
    @media only screen and (min-width: 480px) {
      @content;
    }
  }

  @else if $media == tablet {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }

  @else if $media == desktop {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }

  @else if $media == large-desktop {
    @media only screen and (min-width: 2046px) {
      @content;
    }
  }
}

@mixin themable($theme-name, $theme-map) {
  .#{$theme-name} {
    @each $section, $map in $theme-map {
      .#{$section} {
        background-color: map-get($map, bg);
        color: map-get($map, color);
      }

      @if ($section == compliance__header) {
        background-image: map-get($map, image);
      }
    }
  }
}

@include themable(theme-pc, $theme-pc);

@mixin ellipsis($lines) {
  @supports (-webkit-line-clamp: $lines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $lines;

    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }
}

@mixin title-hover {
  &:hover {
    color: $black;
  }
}
