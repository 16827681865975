.tracker {
  &__container {
    .home {
      &__regupdates {
        flex-direction: column;
        margin: 15px;

        &__header {
          margin-left: 0;
        }

        .home__header__container {
          width: auto;
        }
      }
    }

    .regupdates__table__toggler {
      padding: 20px 14px;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;

    @include breakpoint(tablet) {
      flex-direction: row;
    }
  }
}
