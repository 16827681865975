.globalfilter {
  margin-bottom: 0;
  padding: 30px 15px 15px;

  &__header {
    font-size: 1.3rem;
    padding-bottom: 10px;
    margin: 0;
    font-weight: normal;
    border-bottom: 1px solid $gallery;
  }

  &__buttons {
    margin-top: 5px;

    button {
      background-color: $white;
      border: 1px solid $gallery;
      height: 30px;
      border-radius: 5px;
      padding: 0 15px;
      font-size: 13px;
      font-weight: bold;
      color: $dark-grey;
      transition: color 0.2s ease-in-out;
      width: 100%;

      &:not(:first-of-type) {
        margin-left: 2%;
      }

      &:not(:disabled) {
        color: $shuttle-grey;
        cursor: pointer;

        &:hover {
          color: $dark-grey;
        }
      }
    }
  }
}
