.bookmark {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  background-color: $mercury;
  margin-bottom: 10px;
  border-radius: 5px;

  &__view,
  &__delete {
    text-decoration: none;
    margin-left: auto;
  }
}
