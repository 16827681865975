.regupds {
  width: 100%;

  &__regupdate {
    &__item {
      background-color: $alabaster;
      padding: 10px 10px 0;
      margin: 0 0 20px;
      letter-spacing: 0.5px;
      line-height: normal;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      font-weight: 1000;
      font-size: $largeText;
      margin: 5px 0;
    }

    &__published {
      font-weight: bold;
      font-size: $smallText;

      strong {
        color: $totemPole;
        margin-right: 10px;
      }
    }

    &__content {
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 20px;
      margin: 12px 0 0 0;
    }

    &__footer {
      display: flex;
      font-weight: normal;
      font-size: $smallText;
      line-height: 16px;
      border: 1px solid $medium-grey;
      border-left: 0;
      border-right: 0;
      padding: 5px 0;
    }

    &__link {
      text-decoration: none;
      text-transform: capitalize;
      color: $tuna;
      font-weight: bold;
      font-size: $largeText;
      margin: 10px 0 0;
      display: inline-block;
      cursor: pointer;

      &.unauthorised {
        cursor: default;
      }
    }
  }
}

.regupdates-container {
  padding: 30px;

  &--inbox-view {
    display: flex;

    > div:first-child {
      flex: 1;
    }

    .regupdates__inbox {
      flex: 3;
    }

    .regupds__regupdate__item {
      padding: 10px;
      min-height: 0;
      margin-bottom: 10px;
    }

    .regupds__regupdate__link {
      font-size: $mediumText;
      max-height: 35px;
      overflow: hidden;
      margin: 5px 0 0;

      @include ellipsis(2);

      &--active {
        color: $totemPole;
      }

      @include title-hover;
    }

    .regupds__regupdate__published {
      font-size: $vSmallText;
    }

    .regupdate-container {
      display: flex;
      flex-direction: column;
      padding-top: 0;
    }

    .regupdate {
      margin: 5px 0 20px 30px;
    }

    .article__related {
      margin: 20px 20px 20px 30px;
      border-right: 0;
      padding: 0;
    }
  }

  .reset-date-filter {
    cursor: pointer;
  }
}

.regupdates-container--table-view {
  padding: 15px;

  .dates-between {
    padding: 0;
    margin: 0;
  }

  .fullscreen {
    background: #fff;

    &-enabled {
      .dates-between {
        padding: 0 10px;
      }
    }
  }
}

.compliance__header--scanning {
  ~ .compliance__content__container {
    .regupds__regupdate__link--active {
      color: $totemPole;
    }
  }
}
