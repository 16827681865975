.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  background: transparent url('/images/assets/honeycomb.png') left top;
  padding: 120px 0 0;
  overflow: hidden;

  @include breakpoint(tablet) {
    padding: 150px 0 0;
  }

  &::before {
    content: '';
    position: absolute;
    width: 150%;
    background-color: #da291c;
    height: 179px;
    top: -112px;
    left: -49px;
    transform: skew(63deg) rotate(-4deg);
    z-index: 1;
  }

  &__header {
    color: $white;
    font-size: $largerText;

    @include breakpoint(tablet) {
      font-size: $vLargeText;
    }

    &::after {
      content: '';
      background-color: #fff;
      height: 5px;
      width: 100%;
      z-index: 1;
      position: absolute;
      bottom: -15px;
      left: 0;
      border-radius: 3px;
    }
  }

  &__address {
    color: $white;
    font-size: $mediumText;
    margin: 0 0 10px;

    @include breakpoint(tablet) {
      font-size: $largeText;
    }
  }

  &__addresses {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;

    li:not(:last-child) {
      margin: 0 0 20px;

      @include breakpoint(tablet) {
        margin: 0 60px 40px 0;
        flex-direction: row;
      }
    }

    @include breakpoint(tablet) {
      flex-direction: row;
    }
  }

  &__offices {
    margin: 0 0 38px;

    &__container {
      padding: 0 50px 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      @include breakpoint(tablet) {
        padding: 0 50px 30px 100px;
        width: 55%;
      }
    }

    &__country {
      font-weight: bold;
    }
  }

  &__contact {
    padding: 0 50px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: auto;

    @include breakpoint(tablet) {
      padding: 0 100px 56px;
      width: 45%;
      flex-direction: row;
    }
  }

  &__feedback {
    margin: 0 100px 25px 0;
    cursor: pointer;
  }

  &__contact-us {
    margin: 0 0 25px 0;
    cursor: pointer;
  }

  &__social {
    margin: 0;
    display: flex;
    align-items: center;

    &::after {
      content: none;
    }
  }

  &__social-icon {
    margin-left: 20px;
  }

  &__legal {
    background: $white;
    color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px;
    margin: 0;
    font-size: $mediumText;
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}

.terms__condition__container {
  min-width: 200px;

  a {
    color: $white;
  }
}
