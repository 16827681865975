* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'proxima-nova', 'Open Sans', sans-serif;
  font-size: 1.4rem;
  color: $trout;
}

.theme-pc {
  display: flex;
  flex-direction: row;
  margin: 0;
  flex-wrap: wrap;
  min-height: 100vh;
  align-items: flex-start;
  align-content: flex-start;
  flex: 1 1 auto;
  background: $alabaster;
}

.message {
  padding: 1em;
}

.compliance {
  &__search {
    background: $medium-grey;
    width: 100%;
    flex-basis: 100%;
    padding: 20px;
  }

  &__main-nav {
    flex: 1;
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex: 5;
    width: 100%;
    min-height: calc(100vh - 95px);

    @include breakpoint(tablet) {
      max-width: calc(100% - 270px);
    }
  }

  &__content {
    flex-direction: row;
    flex: 4;
  }

  &__content__container {
    flex-direction: row;
    background-color: $white;
    flex-grow: 1;
    //min-height: 74vh;

    aside {
      flex: 1 0 0;
    }
  }

  &__header {
    height: 75px;
    width: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    font-size: 42px;
    border-top: 1px solid #da291c;

    .no-webp & {
      background: transparent url('/images/assets/banner-map.jpg') no-repeat
        left center / cover;
    }

    .webp & {
      background: transparent url('/images/assets/banner-map.webp') no-repeat
        left center / cover;
    }

    &--insights,
    &--guides,
    &--trackers {
      .no-webp & {
        background-image: url('/images/assets/banner-globe.jpg');
      }

      .webp & {
        background-image: url('/images/assets/banner-globe.webp');
      }
    }

    &--analysis,
    &--scanning {
      .no-webp & {
        background-image: url('/images/assets/banner-map.jpg');
      }

      .webp & {
        background-image: url('/images/assets/banner-map.webp');
      }
    }

    &::before {
      content: '';
      background-color: $white;
      width: 5px;
      height: 45px;
      position: absolute;
      left: 32px;
      z-index: 1;
    }

    @include breakpoint(tablet) {
      height: 104px;

      &::before {
        content: '';
        width: 6px;
        height: 65px;
      }
    }

    @-moz-document url-prefix() {
      display: table;
      table-layout: fixed;
      height: auto;

      &::before {
        top: 40px;
      }
    }
  }

  &__page-title {
    font-size: $largerText;
    font-weight: normal;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 150px;
    background-color: $crimson;
    display: inline-flex;
    align-items: center;
    position: relative;
    overflow: visible;
    padding: 0 0 0 65px;
    z-index: 0;
    text-transform: capitalize;

    @include breakpoint(tablet) {
      font-size: $vLargeText;
      font-size: 3.5rem;
      padding: 0 100px 0 72px;
    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 231px solid $crimson;
      border-left: 153px solid transparent;
      right: -4px;
      top: -12px;
      position: absolute;
      transform: rotate(-60deg);
      z-index: -1;
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 231px solid $crimson;
      border-left: 153px solid transparent;
      right: -13px;
      top: -72px;
      position: absolute;
      transform: rotate(-35deg);
      z-index: -1;
    }

    @include breakpoint(tablet) {
      &::before {
        content: '';
        right: -54px;
        top: 9px;
      }

      &::after {
        content: '';
        right: -63px;
        top: -51px;
      }
    }
  }

  &__rhn {
    padding: 1em;
  }
}

.main-nav.closed + .compliance__container {
  max-width: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .compliance__container {
    flex: auto;
  }

  .compliance__header {
    height: 104px;

    &::before {
      top: 18px;
    }
  }
}
