.requirements {
  padding: 20px;

  .back-select {
    text-decoration: underline;
    cursor: pointer;
  }
}

.requirement-container {
  display: flex;
  flex-direction: column;

  @include breakpoint(tablet) {
    flex-direction: row;
    padding-top: 10px;
  }

  .requirement {
    margin: 30px;
    flex: 3 1;
    border-bottom: 1px solid $geyser;

    @include breakpoint(tablet) {
      margin-right: 20px;
      border-right: 1px solid $geyser;
      border-bottom: 0;
    }
  }
}

.requirement_table {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;

  .fullscreen-enabled & {
    height: calc(100vh - 45px);
  }

  .thead {
    @-moz-document url-prefix() {
      min-width: 0 !important;
      width: 76vw;
    }
  }

  .tbody {
    .tr {
      align-items: center;
      padding: 6px 0;

      &.is-expanded {
        flex-wrap: wrap;
      }

      .td {
        padding-left: 10px;
        border-right: 1px solid rgba(0, 0, 0, 0.02);

        .summary {
          white-space: initial;
        }

        .references {
          white-space: initial;
          display: block;

          &:not(:first-of-type) {
            margin-top: 5px;
          }
        }

        &.expanded-section {
          width: 100%;
        }

        &:last-child {
          margin-left: auto;
        }
      }
    }

    @-moz-document url-prefix() {
      min-width: 0 !important;
      width: 76vw;
    }
  }

  &__link {
    display: block;
    flex: 1 0;
    white-space: initial;
    padding: 7px 5px;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
    color: $tuna;

    &.unauthorised & {
      overflow: visible;
    }

    &.unauthorised {
      filter: none;
      text-decoration: none;
      cursor: default;
      position: relative;

      .icon-padlock {
        position: absolute;
        left: -30px;
        top: 4px;
      }
    }

    img {
      width: 25px;
      height: 25px;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    flex: 1 0 auto;

    .fullscreen-enabled & {
      padding: 10px;
    }
  }

  &__fullscreen-button {
    margin: 0;

    &--icon {
      display: block;
      line-height: 0;
      width: 16px;
      height: 16px;
    }
  }

  &__download {
    margin: 0;

    &-disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  &__column-name {
    text-transform: capitalize;
    text-decoration: underline;
    display: inline-block;
  }

  &__hide-column {
    margin-left: 8px;
  }

  &__toggler {
    padding: 0 5px;
  }

  &__hidden-columns-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -3px 0;
  }

  &__hidden-columns-button {
    text-transform: capitalize;
    border-radius: 4px;
    color: $white;
    background-color: $totemPole;
    margin: 3px 5px;

    &--inverted {
      background-color: $white;
      color: $totemPole;
      font-weight: bold;
      border: 1px solid $totemPole;
    }

    label {
      padding: 4px 8px;
      display: block;
      margin: 0;
      cursor: pointer;
    }

    input {
      display: none;
    }
  }

  .table {
    display: block;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.1);

    .expander {
      border-radius: 50%;
      background-color: $totemPole;
      width: 30px;
      min-width: 30px;
      height: 30px;
      color: white;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .th {
      &:not(.th--can-resize) {
        flex-shrink: 0;
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      position: relative;

      .resizer {
        right: -5px;
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        touch-action: none;
      }
    }

    .th {
      position: relative;
      background-color: $totemPole;
      color: $white;
      font-size: $smallText;
      padding: 15px 10px;

      &--can-resize {
        &::after {
          content: '';
          position: absolute;
          width: 1px;
          background: rgba(0, 0, 0, 0.05);
          z-index: 1;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }

      &:last-of-type {
        .resizer {
          right: -15px;
        }
      }
    }

    .tr {
      background-color: rgba($totemPole, 0.2);

      &:nth-child(odd) {
        background-color: $alabaster;
      }

      &.is-expanded {
        flex-wrap: wrap;
      }

      .expanded-section {
        width: 100%;

        &,
        .tr {
          background: $light-grey !important;
        }

        .td:first-child,
        .th:first-child {
          width: 20px !important;
        }

        .tbody {
          min-width: 100% !important;
        }
      }
    }
  }

  &--timelines {
    .th,
    .td {
      &:not(:first-child):not(:last-child) {
        flex: 1 1 auto !important;
      }
    }

    .table {
      overflow: auto;
      padding: 0;

      .td {
        padding: 0 10px;
        background-color: $light-grey !important;
      }

      a {
        padding: 0;
      }

      .thead {
        .tr {
          background-color: $iron;
          padding: 0;
        }
      }

      .th {
        padding: 0.5rem;
        min-height: 25px;
        background-color: $iron;
        color: $tuna;
        box-shadow: none;

        &::after {
          display: none;
        }
      }
    }
  }

  .pagination {
    margin-top: auto;
    padding: 3px;
    display: flex;
    justify-content: space-between;

    .previous,
    .next {
      flex: 1 1 250px;
    }

    .center {
      padding-left: 3px;
      padding-right: 3px;
      flex: 0 1 auto;
    }

    .pager {
      display: inline-block;
      margin: 3px 10px;
      white-space: nowrap;
    }

    button {
      background-color: #383b41;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      cursor: pointer;
      font-size: 1em;
      max-width: 250px;

      &[disabled] {
        opacity: 0.5;
        cursor: default;
      }
    }

    input[type='number'] {
      width: 70px;
      text-align: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 5px 7px;
      font-size: inherit;
      border-radius: 3px;
      font-weight: 400;
      outline-width: 0;
    }

    select {
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 5px 7px;
      font-size: inherit;
      border-radius: 3px;
      font-weight: 400;
      outline-width: 0;
    }
  }
}

.under_review_container {
  border: lightcoral 1px solid;
  padding: 10px;
  margin: 10px;
  background-color: lavenderblush;
}
