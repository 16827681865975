.arts {
  &__article {
    background-color: $alabaster;
    padding: 10px 10px 0;
    margin: 0 0 20px;
    letter-spacing: 0.5px;
    line-height: normal;
    width: 100%;
    min-height: 70px;

    &:last-child {
      margin-bottom: 0;
    }

    &__container {
      display: flex;
    }

    &__checkbox {
      margin: auto auto auto 7px;
    }

    &__title {
      font-weight: 1000;
      font-size: $largeText;
      margin: 5px 0;
    }

    &__published {
      font-weight: bold;
      font-size: $smallText;

      strong {
        color: $cerulean;
      }
    }

    &__date {
      font-weight: 20;
    }

    &__content {
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 20px;
      margin: 12px 0 0 0;

      @include ellipsis(2);
    }

    &__link {
      text-decoration: none;
      text-transform: capitalize;
      color: $tuna;
      font-weight: bold;
      font-size: $largeText;
      margin-top: 10px;
      display: inline-block;
      cursor: pointer;

      @include title-hover;
    }

    &__tags {
      display: flex;
      justify-content: flex-start;
      border-top: 1px solid #e2e2e5;
      margin-top: 10px;
      padding-top: 10px;
      flex-wrap: wrap;
    }
  }

  &__articles {
    &--grid-view {
      display: flex;
      flex: 1 1;
      flex-wrap: wrap;

      .arts__article {
        flex: 1 1 30%;
        margin: 13px;
      }

      .tags {
        &__container {
          display: block;
        }

        &__title {
          margin-bottom: 10px;
        }
      }
    }
  }

  &__articles-container {
    padding: 30px;

    &--inbox-view {
      display: flex;

      > div:first-child {
        flex: 1;
      }

      .arts__article {
        padding: 10px;
        min-height: 0;
        margin-bottom: 10px;
      }

      .arts__articles__inbox {
        flex: 3;
      }

      .arts__article__link {
        font-size: $mediumText;
        max-height: 35px;
        overflow: hidden;
        margin: 5px 0 0;

        @include ellipsis(2);

        &--active {
          color: $cerulean;
        }

        @include title-hover;
      }

      .arts__article__published {
        font-size: $vSmallText;
      }

      .article-container {
        flex-direction: column;
        padding-top: 0;
      }

      .article {
        margin: 5px 0 20px 30px;
        flex: 2 1;

        &__related {
          margin: 20px 20px 0 30px;
          // border-right: 0;
          // padding-right: 0;
        }
      }
    }
  }
}

.compliance__header--insights {
  ~ .compliance__content__container {
    .arts__article__published {
      strong,
      a {
        color: $shipCove;
      }
    }

    .arts__article__link--active {
      color: $shipCove;
    }
  }
}

.compliance__header--analysis {
  ~ .compliance__content__container {
    .arts__article__published {
      strong,
      a {
        color: $cerulean;
      }
    }

    .arts__article__link--active {
      color: $cerulean;
    }
  }
}
