.settings {
  display: flex;
  flex-direction: column;
  background-color: $geyser;
  min-height: 74vh;

  @include breakpoint(tablet) {
    flex-direction: row;
  }

  &__tabList {
    width: auto;
    flex-direction: row;
    display: flex;

    @include breakpoint(tablet) {
      width: 260px;
      flex-direction: column;
    }

    &__item {
      padding: 20px;
      background-color: $porcelain;
      font-weight: bold;
      cursor: pointer;
      margin-bottom: 5px;
      flex: 1;

      @include breakpoint(tablet) {
        padding: 20px 0 20px 40px;
        flex: none;
      }

      &--active {
        background-color: $trout;
        color: $white;
      }
    }
  }

  &__tabPanel {
    background-color: $white;
    width: 100%;
    padding: 20px;
  }
}

.notifications {
  &__type {
    background-color: $alabaster;
    padding: 10px;
    margin: 0 0 10px;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__heading {
    margin: 0;
    font-size: $mediumText;
    color: $trout;

    span {
      text-transform: capitalize;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  position: relative;
}

.my-favourites {
  &__item {
    background-color: $alabaster;
    padding: 10px 10px 0;
    margin: 0 0 10px;
    line-height: normal;
    min-height: 70px;
    //display: flex;
    //justify-content: space-between;

    &:first-child {
      border: 0;
      background: none;
      min-height: 0;
    }

    &-controls {
      display: flex;
      margin-left: auto;
      align-items: flex-end;

      button:first-of-type {
        margin-left: auto;
      }
    }

    &__link {
      display: inline-block;
      color: $tuna;
      font-weight: bold;
      max-width: 140px;

      @include breakpoint(tablet) {
        flex-direction: column;
        max-width: none;
      }
    }

    .button {
      &:first-child {
        margin-left: 20px;
      }

      &--link:not(:first-child) {
        white-space: nowrap;
      }
    }
  }

  &__filters {
    display: flex;
    padding: 8px 8px 0 0;
    border-radius: 3px;
    border-top: 1px solid $iron;
    flex-wrap: wrap;
  }

  &__header {
    text-transform: capitalize;
    margin: 10px 0;
    font-size: $largeText;
  }

  &__filter {
    background-color: #e2e2e5;
    border-radius: 5px;
    padding: 7px;
    margin: 0 10px 10px 0;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: #4a4e60;
    white-space: nowrap;
    cursor: default;
  }

  input[type='text'] {
    display: inline-block;
    width: auto;
    margin: 0 0 5px;
    padding: 3px 10px;

    &::placeholder {
      color: $silver;
    }
  }
}

.my-profile {
  &__header {
    color: $cerulean;
    margin: 0 0 5px;
  }

  &__name {
    margin: 0;
  }

  &__item {
    background-color: $alabaster;
    padding: 10px;
    margin: 0 0 10px;
    line-height: normal;
    width: 50%;
  }

  p {
    margin-bottom: 0;
  }
}

.switch {
  cursor: pointer;
  margin: 0 0 0 20px;

  input {
    display: none;

    + span {
      width: 48px;
      height: 28px;
      border-radius: 14px;
      transition: all 0.3s ease;
      display: block;
      position: relative;
      background: $frenchGrey;
      box-shadow: 0 8px 16px -1px rgba($frenchGrey, 0.2);

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        transition: all 0.3s ease;
      }

      &::before {
        top: 5px;
        left: 5px;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        border: 5px solid #fff;
      }

      &:active {
        transform: scale(0.92);
      }
    }

    &:checked {
      + span {
        background: $crimson;

        &::before {
          margin-left: 20px;
        }
      }
    }
  }
}
