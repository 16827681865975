.related {
  &__date,
  &__title,
  &__body {
    color: #888;
  }

  &__header {
    margin-top: 20px;
    font-size: $mediumText;
    padding-bottom: 10px;
    border-bottom: 1px solid $geyser;
  }

  &__items {
    margin-bottom: 30px;

    &:last-child {
      margin: 0;
    }
  }

  &__item {
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $geyser;

    &:last-child {
      border: 0;
    }
  }

  &__title {
    font-size: $mediumText;
    font-weight: 600;
    margin: 0;
    text-decoration: none;

    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
  }

  &__date {
    margin: 10px 0 0;
    display: inline-block;
    font-size: $vSmallText;
  }

  &__body {
    margin: 10px 0 0;
    font-size: $smallText;
    line-height: 16px;
  }
}
