.home {
  &__curated {
    margin-top: 5px;
  }

  &__carousels {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 15px 15px 0;
    align-items: flex-end;
    //width: calc(100vw - 302px);

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    @-moz-document url-prefix() {
      width: calc(100vw - 320px);
    }
  }

  &__carousel {
    min-width: 0;
    width: 100%;
  }

  &__articles,
  &__guides,
  &__regupdates {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-container {
      margin: 15px;
      background-color: $white;
    }

    &-list {
      display: flex;
      flex-direction: column;
      margin: 15px;

      @include breakpoint(tablet) {
        flex-direction: row;
      }
    }

    &__header {
      font-size: $largerText;
      font-weight: bold;
      color: $shipCove;
      display: inline-block;
      margin: 5px 12px 0;

      &--analysis {
        color: $cerulean;
      }

      &--link {
        float: right;
        color: $tuna;
        font-size: $smallText;
        font-weight: normal;
      }
    }
  }

  &__article,
  &__item {
    background-color: $alabaster;
    padding: 10px 20px;
    margin: 13px;
    letter-spacing: 0.5px;
    line-height: normal;
    flex: 1;

    &__title {
      font-weight: 600;
      font-size: $largerText;
      // font-size: $headlineText;
      margin-top: 5px;
      text-decoration: none;
      color: $tuna;

      @include ellipsis(3);
      @include title-hover;
    }

    &__date {
      font-weight: 700;
      font-size: $smallText;
      margin-bottom: 15px;

      strong,
      a {
        color: $crimson;
      }
    }

    &__content {
      font-weight: normal;
      font-size: $mediumText;
      line-height: 20px;
      max-height: 100px;
      overflow: hidden;

      @include ellipsis(3);

      margin-bottom: 35px;
    }

    &__link {
      text-decoration: none;
      text-transform: capitalize;
      font-size: $smallText;
      color: $white;
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 8px;
      opacity: 0.9;
      background-color: $shipCove;

      &--carousel {
        background-color: transparent;
        position: static;
        padding: 0;
        color: $crimson;
      }

      &--analysis {
        background-color: $cerulean;
      }
    }
  }

  &__item {
    position: relative;
    overflow: hidden;

    &__date {
      strong,
      a {
        color: $shipCove;
      }

      &--analysis {
        strong,
        a {
          color: $cerulean;
        }
      }
    }
  }

  &__header__container {
    width: 92%;
    margin: 15px 15px 0;

    @include breakpoint(tablet) {
      width: 100%;
    }
  }

  &__guides {
    padding: 15px;
    flex-direction: column;
    background-color: #e8e7e7;

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    .home__header__container {
      margin: 0;
      width: auto;

      @include breakpoint(tablet) {
        margin: 0 15px;
        width: 100%;
      }

      &--empty {
        margin: 0;
      }
    }

    .home__item {
      &__date strong {
        color: $astronaut;
      }

      &__link {
        background-color: $astronaut;
      }
    }
  }

  &__regupdates {
    flex-direction: column;

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    &__header {
      color: $totemPole;
    }

    .home__item {
      &__date strong {
        color: $totemPole;
      }

      &__link {
        background-color: $totemPole;
      }
    }
  }

  &__articles-list {
    margin: 7px 15px 15px;
    width: 100%;
  }

  &__guides-list,
  &__regupdates-list {
    width: auto;
    margin: 0;

    @include breakpoint(tablet) {
      width: calc(67% - 30px);
      margin: 7px 15px 15px;
    }

    .home__item {
      flex: 1;
    }
  }

  &__guides-sublist,
  &__regupdates-sublist {
    width: auto;
    margin: 0 30px 30px;

    @include breakpoint(tablet) {
      margin: 15px 30px 30px 0;
      flex-direction: row;
      width: calc(33% - 30px);
    }

    .home__item {
      background-color: $white;
      padding: 0;
      border-bottom: 1px solid #aaa;
      line-height: inherit;
      margin: 0;

      &__link {
        font-weight: bold;
        font-size: $smallText;

        @include ellipsis(2);
      }
    }
  }

  &__regupdates-sublist {
    .home__item {
      &__date {
        margin: 10px 0 5px;
      }

      &__title {
        margin-bottom: 12px;

        &.unauthorised {
          padding-left: 7px;
        }
      }

      &__link {
        position: static;
        background-color: $white;
        color: $tuna;
        padding: 0;
        font-size: $mediumText;

        @include title-hover;
      }
    }
  }

  &__guides-sublist {
    margin: 13px;
    align-items: stretch;
    display: flex;
    flex-direction: column;

    @include breakpoint(tablet) {
      margin: 20px 30px 30px 0;
    }

    .home__item {
      padding: 10px 20px 20px;
      border: 0;
      margin: 0;

      &__title {
        padding-right: 55px;
        max-height: 90px;

        @include ellipsis(2);
      }

      &__date {
        margin-top: 0;
      }

      &:first-child {
        margin-bottom: 30px;
      }

      &--single {
        margin: 0;

        &:first-child {
          margin-bottom: 0;
        }

        .home__item {
          &__date {
            margin-top: 12px;
          }

          &__title {
            padding-right: 40px;
          }
        }
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .home__articles-list {
    width: 100%;
  }
}
