.ReactModal {
  &__Overlay {
    align-items: center;
    display: none;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 100;
  }

  &__Content {
    outline: none;
    background: $white;
    border-radius: 5px;
    min-height: 100px;
    padding: 24px;
    position: relative;
    box-shadow: 1px 3px 5px $santasGrey;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 16px;
    max-width: 515px;
    max-height: calc(100vh - 32px);

    input[type='text'] {
      border: 0;
      background-color: $alabaster;
      border-bottom: 1px solid $gallery;
    }

    &__link {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .modal-content {
      margin: 0 -24px -24px;
      padding: 0 24px 24px;
      overflow: auto;
    }

    &.unauthorised {
      width: 90%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      filter: blur(0);
      flex-direction: column;
      padding: 20px;

      @include breakpoint(tablet) {
        width: 500px;
        min-width: 500px;
        padding: 80px;
      }

      .modal-content {
        margin: 0;
        padding: 24px 0 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }
    }

    &.unconstrained {
      max-width: none;
      max-height: none;
    }

    .modal-header {
      display: flex;
      align-items: center;
      margin: -24px -24px 0;
    }

    .modal-title {
      font-size: $largerText;
      font-weight: bold;
      line-height: 1;
      padding: 18px 24px;
      flex-grow: 1;
    }

    .modal-close-btn {
      cursor: pointer;
      color: $crimson;
      font-size: 2.2rem;
      line-height: 1;
      position: absolute;
      top: 0;
      right: 2.2rem;
      margin-top: 1.8rem;
      z-index: 2;
    }

    .modal-unauthorised {
      font-size: 1.6rem;
      margin-bottom: 20px;
      line-height: 25px;
      text-align: left;
      padding-left: 75px;

      .icon-padlock {
        position: absolute;
        left: 0;
        width: 65px;
        top: 3px;
      }

      ~ .button--link {
        pointer-events: auto;
        margin-left: auto;
      }
    }
  }

  &__Body--open {
    overflow: hidden;

    .ReactModal__Overlay {
      display: flex;
    }
  }
}
